import './modules/modals';
import './modules/forms';
import './modules/masks';
import './modules/spinner';
import './modules/scroll';
import './modules/menus';
import './modules/tabs';
import './modules/galleries';
import './modules/rotators';
import './modules/spoilers';
import './modules/lazy-load';
import './modules/header';
import './modules/filters';
import './modules/alphabet-filter';
import './modules/card';
import './modules/order-fixed-panel';
import './modules/poster-size-calculator';
import './modules/partitions';
import InstagramWidget from './modules/widgets/instagram';

// Init instagram widget
// ---------------------

const instagram = new InstagramWidget({
  limit: {
    default: 12,
    mobile: 12,
  },
});
